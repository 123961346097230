import { Money } from "./money";

export class Account extends Money {
  id: number;
  name: string;

  constructor(data: any) {
    super(data);
    this.id = data.id;
    this.name = data.name;
  }
}