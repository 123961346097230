import { Button } from "@mui/material";
import React from "react";
import { Account, Transaction } from "../../../../../domain";
import { getTransactions } from "../../../../../process";
import { QuickActions } from "../../../../components/quickActions";
import { ViewType } from "../views";
import { TransactionList } from "./transactionList";

interface AccountViewProps {
  account: Account,
  setView: (view: ViewType) => void
}

export function AccountView(props: AccountViewProps) {
  const { account } = props;

  const [transactions, setTransactions] = React.useState<Transaction[]>([]);

  React.useEffect(() => {
    getTransactions(account.id)
      .then(transactions => setTransactions(transactions));
  }, [account]);

  const backButton = (
    <Button onClick={() => props.setView("accounts")} sx={{ color: "white", fontSize: "large" }}>
      <span style={{ fontWeight: "lighter", marginRight: "0.5em", opacity: "0.5" }}>{"<"}</span>{"Home"}
    </Button>
  );
  return (
    <>
      <QuickActions header={backButton} />
      <TransactionList
        transactions={transactions}
      />
    </>
  );
}