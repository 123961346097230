export const host = process.env.REACT_APP_API_URI;

export function objectToFormData(object: any): FormData {
  const data = new FormData();
  Object.keys(object).forEach((key) => data.append(key, object[key]));
  return data;
}

export function createPostRequest(data: any): RequestInit {
  return {
    method: "POST",
    mode: "cors" as RequestMode,
    body: objectToFormData(data),
  };
}

class Api {
  post(endpoint: Endpoint, body: any): Promise<any[]> {
    return fetch(host + endpoint.value, createPostRequest(body))
      .then(response => response.json())
      .then(json => JSON.parse(json))
      .then(json => (!!json.error) ? Promise.reject(json) : json)
      .then(data => data.items);
  }
}

export const api = new Api();

export class Endpoint {
  constructor(public readonly value: string) {
  }

  static get login() { return new Endpoint("login.php"); }
  static get getCharacters() { return new Endpoint("get_characters.php"); }
  static get getAccounts() { return new Endpoint("get_accounts.php"); }
  static get addAccount() { return new Endpoint("add_account.php"); }
  static get getTransactions() { return new Endpoint("get_transactions.php"); }
  static get sendMoney() { return new Endpoint("send_money.php"); }
  static get receiveMoney() { return new Endpoint("receive_money.php"); }
}