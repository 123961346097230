import { Endpoint, api } from "../data/api";
import { Account, Money } from "../domain";

export function receiveMoney(amount: number, from: string, to: Account, description: string) {
  const wholeAndCents = Money.fromNumber(amount).wholeAndCents;
  const data = {
    accountId: to.id,
    cp: wholeAndCents.cents,
    sp: 0,
    gp: wholeAndCents.whole,
    ep: 0,
    pp: 0,
    sender: from,
    description: description
  }

  return api.post(Endpoint.receiveMoney, data);
}