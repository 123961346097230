import React from "react";
import { Account, Character } from "../../../../../domain";
import { QuickAction, QuickActions } from "../../../../components/quickActions";
import { ViewType } from "../views";
import { AccountList } from "./accountList";
import { AddAccountDialog } from "./addAccountDialog";

interface AccountsViewProps {
  character: Character,
  accounts: Account[],
  setView: (view: ViewType) => void,
  setAccount: (account: Account) => void
}

export function AccountsView(props: AccountsViewProps) {
  const { character, accounts, setView } = props;

  const [open, setOpen] = React.useState(false);

  const setOpenAndRefresh = (open: boolean) => {
    setOpen(open);
    if (!open) {
      props.setView("accounts");
    }
  }

  const setAccount = (account: Account) => {
    props.setAccount(account);
    props.setView("account");
  };

  const total = accounts.map(account => account.combineFunds())
    .reduce((a, b) => a + b, 0);

  const header = (<>
    <h3>Welcome back, {props.character.name.split(" ")[0]}! 👋</h3>
    <p>Total: {total} GP</p>
    <hr />
  </>)

  return (
    <>
      <QuickActions header={header} >
        <QuickAction label="Create Account" action={() => setOpen(true)} />
        <QuickAction label="Transfer Money" action={() => setView("transfer")} />
        <AddAccountDialog open={open} setOpen={setOpenAndRefresh} character={character} accounts={accounts} />
      </QuickActions>
      <AccountList accounts={accounts} setAccount={setAccount} />
    </>
  )
}