import React from "react";

interface QuickActionsProps {
  children?: React.ReactNode,
  header?: React.ReactNode
}

export function QuickActions(props: QuickActionsProps) {
  return (
    <div className="quick-info">
      {props.header}
      <div className="quick-info-buttons">
        {props.children}
      </div>
    </div>
  )
}