import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, SxProps, Theme } from "@mui/material";
import { Account } from "../../../../../../domain";

interface AccountDropdownProps {
  label: string,
  accounts: Account[],
  account: Account,
  setAccount: (account: Account) => void,
  sx: SxProps<Theme>
}

export function AccountDropdown(props: AccountDropdownProps) {
  const { accounts, account, setAccount, label, sx } = props;

  const handleChange = (event: SelectChangeEvent) => {
    const account = accounts.find(account => account.name === event.target.value);
    setAccount(account!);
  };

  return (
    <FormControl sx={sx}>
      <InputLabel id="select-account-label">{label}</InputLabel>
      <Select
        labelId="select-account-label"
        id="select-account"
        value={account.name}
        label={label}
        onChange={handleChange}
        required
      >
        {accounts.map(account => <MenuItem key={account.id} value={account.name}>
          <span style={{ width: "100%", display: "flex", justifyContent: "space-between" }} >
            <span>{account.name}</span>
            <span style={account.combineFunds() >= 0 ? { color: "green" } : { color: "red" }}>
              {account.combineFunds().toFixed(2).toString()} GP
            </span>
          </span>
        </MenuItem>)}
      </Select>
    </FormControl>
  );
}