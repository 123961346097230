import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UserData } from '../domain';
import './App.css';
import Home from "./pages/home";
import Login from "./pages/login";

export default function App() {
  const [user, setUser] = React.useState<UserData>();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={user ? <Home user={user} /> : <Login setUser={setUser} />} />
      </Routes>
    </BrowserRouter>
  );
}