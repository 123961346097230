import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';
import { Account, Character } from '../../../../../domain';
import { QuickActions } from '../../../../components/quickActions';
import { ViewType } from '../views';
import { MoveMoney } from './moveMoney';
import { ReceiveMoney } from './receiveMoney';
import { SendMoney } from './sendMoney';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TransferViewProps {
  accounts: Account[],
  character: Character,
  setView: (view: ViewType) => void
}

export function TransferView(props: TransferViewProps) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const backButton = (
    <Button onClick={() => props.setView("accounts")} sx={{ color: "white", fontSize: "large" }}>
      <span style={{ fontWeight: "lighter", marginRight: "0.5em", opacity: "0.5" }}>{"<"}</span>{"Home"}
    </Button>
  );

  return (
    <>
      <QuickActions header={backButton} />
      <Box sx={{ width: '100%', maxWidth: "800px", marginLeft: "auto", marginRight: "auto" }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable">
            <Tab label="Send money" {...a11yProps(0)} />
            <Tab label="Receive money" {...a11yProps(1)} />
            <Tab label="Move money between accounts" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <SendMoney accounts={props.accounts} setView={props.setView} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <ReceiveMoney accounts={props.accounts} setView={props.setView} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <MoveMoney accounts={props.accounts} setView={props.setView} />
        </CustomTabPanel>
      </Box>
    </>
  );
}