import React from "react";
import { Header } from "./header";
import { View } from "./view";

import { Character, UserData } from "../../../domain/types";
import { getCharacters } from "../../../process";
import "./home.css";

interface HomeProps {
  user: UserData
}

export default function Home(props: HomeProps) {
  const [character, setCharacter] = React.useState<Character | undefined>();
  const [characters, setCharacters] = React.useState<Character[]>([]);

  React.useEffect(() => {
    getCharacters(props.user.id, setCharacters, setCharacter);
  }, [props.user.id]);

  return (
    <div className="App">
      <Header character={character} setCharacter={setCharacter} characters={characters} />
      {character ? (<View character={character} />) : <p>Loading data</p>}
    </div>
  );
}