import { Money } from "./money";

export class Transaction extends Money {
  id: string;
  sender: string;
  recipient: string;
  description: string;
  incoming: boolean;

  constructor(data: any) {
    super(data);
    this.id = data.id;
    this.sender = data.sender;
    this.recipient = data.recipient;
    this.description = data.description;
    this.incoming = data.incoming;
  }

  static fromDb(json: any): Transaction {
    return new Transaction({
      id: json.id,
      sender: json.sender,
      recipient: json.recipient,
      cp: json.cp,
      sp: json.sp,
      ep: json.ep,
      gp: json.gp,
      pp: json.pp,
      description: json.description,
      incoming: json.send_receive === 1,
    });
  }
}
