
import { Box } from "@mui/material";
import React from "react";
import { Account } from "../../../../../domain";
import { moveMoney } from "../../../../../process";
import { ViewType } from '../views';
import { AccountDropdown } from "./components/accountDropdown";
import { AmountInput } from "./components/amountInput";
import { Description } from './components/description';
import { ErrorMessage } from "./components/errorMessage";
import { SubmitButton } from "./components/submitButton";

interface SendMoneyProps {
  accounts: Account[],
  setView: (view: ViewType) => void
}

export function MoveMoney(props: SendMoneyProps) {
  const { accounts, setView } = props;
  const [amount, setAmount] = React.useState(0);
  const [sender, setSender] = React.useState(accounts[0]);
  const [recipient, setRecipient] = React.useState(accounts[0]);
  const [description, setDescription] = React.useState("Transfer money between accounts");
  const [errorMessage, setErrorMessage] = React.useState("");

  const MoveMoney = (e: React.FormEvent) => {
    e.preventDefault();

    moveMoney(amount, sender, recipient, description)
      .then(() => setView("accounts"))
      .catch(e => setErrorMessage(e.toString()));
  }

  const extraSpace = window.innerWidth <= 550 ? <br /> : <></>;
  const flexDirection = window.innerWidth <= 550 ? "column" : "row";
  const width = window.innerWidth <= 550 ? "100%" : "48%";

  return (
    <form onSubmit={MoveMoney}>
      <Box sx={{ display: "flex", flexDirection, justifyContent: "space-between" }}>
        <AccountDropdown label="From" accounts={accounts} account={sender} setAccount={setSender} sx={{ width }} />
        {extraSpace}
        <AccountDropdown label="To" accounts={accounts} account={recipient} setAccount={setRecipient} sx={{ width }} />
      </Box>
      <br />
      <AmountInput amount={amount} setAmount={setAmount} />
      <ErrorMessage message={errorMessage} />
      <Description description={description} setDescription={setDescription} />
      <SubmitButton label="Move" />
    </form >
  );
}