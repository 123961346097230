interface WholeAndCents {
  whole: number;
  cents: number;
}

export class Money {
  cp: number;
  sp: number;
  ep: number;
  gp: number;
  pp: number;

  constructor(data: any) {
    this.cp = data.cp ?? 0;
    this.sp = data.sp ?? 0;
    this.ep = data.ep ?? 0;
    this.gp = data.gp ?? 0;
    this.pp = data.pp ?? 0;
  }

  combineFunds(): number {
    return (this.cp * 1 + this.sp * 10 + this.ep * 50 + this.gp * 100 + this.pp * 1000) / 100;
  }

  get wholeAndCents(): WholeAndCents {
    const combined = this.combineFunds();
    const whole = Math.floor(combined);
    const cents = Math.floor((combined - whole) * 100);
    return { whole, cents };
  }

  static fromNumber(amount: number): Money {
    return new Money({
      cp: Math.floor(amount * 100),
    });
  }
}