import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import React from "react";
import { Transaction } from "../../../../../domain";

interface TransactionListItemProps {
  transaction: Transaction
}

export function TransactionListItem(props: TransactionListItemProps) {
  const { transaction } = props;

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange = (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion key={transaction.id} expanded={expanded === transaction.id} onChange={handleChange(transaction.id)}>
      <AccordionSummary>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <span>
            {transaction.incoming ? transaction.sender : transaction.recipient}
          </span>
          <span style={transaction.incoming ? { color: "green" } : { color: "red" }}>
            {(transaction.incoming ? "+" : "-") + transaction.combineFunds().toFixed(2).toString() + " GP"}
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {transaction.description}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}