import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Account } from "../../../../../domain";

const itemStyle = {
  padding: 0
};

interface AccountListItemProps {
  account: Account,
  setAccount: (account: Account) => void
}

export function AccountListItem(props: AccountListItemProps) {
  const { account, setAccount } = props;

  return (
    <ListItem key={account.id} sx={itemStyle}>
      <ListItemButton
        onClick={() => setAccount(account)}
      >
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <ListItemText>{account.name}</ListItemText>
          <ListItemText sx={{ textAlign: "right" }}>
            <span style={account.combineFunds() > 0 ? { color: "green" } : { color: "red" }}>
              {account.combineFunds().toFixed(2).toString() + " GP"}
            </span>
          </ListItemText>
        </div>
      </ListItemButton>
    </ListItem>
  );

}