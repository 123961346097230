import { FormControl, SxProps, TextField, Theme } from "@mui/material";

interface TextInputProps {
  label: string,
  value: string,
  setValue: (value: string) => void,
  sx: SxProps<Theme>
}

export function TextInput(props: TextInputProps) {
  const { label, value, setValue, sx } = props;

  return (
    <FormControl sx={sx}>
      <TextField
        required
        id="recipient"
        label={label}
        defaultValue={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </FormControl>
  );
}