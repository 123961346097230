import { Box } from "@mui/material";

const actionStyle = {
  width: "30%",
  height: "3em",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.644)",
  borderRadius: "10px",
  textAlign: "center",
  paddingTop: "5px",
  background: "linear-gradient(27deg, rgba(9, 9, 121, 1) 0 %, rgba(0, 90, 161, 1) 100 %)",
  whiteSpace: "pre-wrap",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "5px",
  paddingLeft: "10px",
  paddingRight: "10px",
}

interface QuickActionProps {
  label: string,
  action: () => void
}

export function QuickAction(props: QuickActionProps) {
  const { label, action } = props;

  return (
    <Box sx={actionStyle} onClick={() => action()}>
      <h3>
        {label}
      </h3>
    </Box>
  );
}