import React, { useCallback } from "react";
import { Account, Character } from "../../../domain";
import { getAccounts } from "../../../process/getAccounts";
import "./view.css";
import { AccountView, AccountsView, TransferView, ViewType } from "./views/views";

interface ViewProps {
  character: Character,
}

export function View(props: ViewProps) {
  const { character } = props;

  const [selectedAccount, setSelectedAccount] = React.useState<Account>();
  const [accounts, setAccounts] = React.useState<Account[]>([]);
  const [view, setView] = React.useState<ViewType>("accounts");

  const setViewAndRefresh = (view: ViewType) => {
    getAndSetAccounts()
      .then(() => setView(view));
  }

  const getAndSetAccounts = useCallback(() => getAccounts(character.id)
    .then(accounts => {
      setSelectedAccount(accounts[0]);
      setAccounts(accounts);
    }), [character]);

  React.useEffect(() => {
    getAndSetAccounts();
  }, [getAndSetAccounts]);

  return (
    <main>
      {(view === "accounts") ? <AccountsView character={character} accounts={accounts} setView={setViewAndRefresh} setAccount={setSelectedAccount} /> : <></>}
      {(view === "account") ? <AccountView account={selectedAccount!} setView={setViewAndRefresh} /> : <></>}
      {(view === "transfer") ? <TransferView accounts={accounts} setView={setViewAndRefresh} character={character} /> : <></>}
    </main>
  )
}