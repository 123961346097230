import { Box } from "@mui/material";
import React from "react";
import { Account } from "../../../../../domain";
import { receiveMoney } from "../../../../../process";
import { ViewType } from '../views';
import { AccountDropdown } from "./components/accountDropdown";
import { AmountInput } from './components/amountInput';
import { Description } from './components/description';
import { ErrorMessage } from "./components/errorMessage";
import { SubmitButton } from "./components/submitButton";
import { TextInput } from "./components/textInput";

interface SendMoneyProps {
  accounts: Account[],
  setView: (view: ViewType) => void
}

export function ReceiveMoney(props: SendMoneyProps) {
  const { accounts, setView } = props;
  const [account, setAccount] = React.useState(accounts[0]);
  const [amount, setAmount] = React.useState(0);
  const [sender, setSender] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleReceiveMoney = (e: React.FormEvent) => {
    e.preventDefault();

    receiveMoney(amount, sender, account, description)
      .then(() => setView("accounts"))
      .catch(e => setErrorMessage(e.toString()));
  }

  const extraSpace = window.innerWidth <= 550 ? <br /> : <></>;
  const flexDirection = window.innerWidth <= 550 ? "column" : "row";
  const width = window.innerWidth <= 550 ? "100%" : "48%";

  return (
    <form onSubmit={handleReceiveMoney}>
      <Box sx={{ display: "flex", flexDirection, justifyContent: "space-between" }}>
        <TextInput label="From" value={sender} setValue={setSender} sx={{ width }} />
        {extraSpace}
        <AccountDropdown label="To" accounts={accounts} account={account} setAccount={setAccount} sx={{ width }} />
      </Box>
      <br />
      <AmountInput amount={amount} setAmount={setAmount} />
      <ErrorMessage message={errorMessage} />
      <Description description={description} setDescription={setDescription} />
      <SubmitButton label="Receive" />
    </form >
  );
}