import { Transaction } from "../../../../../domain";
import { TransactionListItem } from "./transactionListItem";

const listStyle = {
  borderRadius: "10px",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.644)",
  margin: "1em"
};

interface TransactionListProps {
  transactions: Transaction[],
};

export function TransactionList(props: TransactionListProps) {
  const { transactions } = props;

  return (
    <div style={listStyle}>
      {
        transactions.map((transaction) => <TransactionListItem key={transaction.id} transaction={transaction} />)
      }
    </div>
  );
}