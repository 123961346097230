import { List, ListSubheader } from "@mui/material";
import { Account } from "../../../../../domain";
import { AccountListItem } from "./accountListItem";

const listStyle = {
  borderRadius: "10px",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.644)",
  margin: "1em"
};

interface AccountListProps {
  accounts: Account[],
  setAccount: (account: Account) => void
};

export function AccountList(props: AccountListProps) {
  const { accounts, setAccount } = props;

  return (<List
    sx={listStyle}
    subheader={<ListSubheader>Accounts</ListSubheader>}
  >
    {
      accounts.map((account) => <AccountListItem key={account.id} account={account} setAccount={setAccount} />)
    }
  </List>);
}