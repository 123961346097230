import InfoIcon from '@mui/icons-material/Info';
import { FormControl, InputAdornment, TextField, Tooltip } from "@mui/material";

interface AmountInputProps {
  amount: number,
  setAmount: (amount: number) => void
}

export function AmountInput(props: AmountInputProps) {
  const { amount, setAmount } = props;

  return (
    <FormControl fullWidth variant="outlined">
      <TextField
        label="Amount (GP)"
        required
        variant="outlined"
        id="amount"
        type="float"
        defaultValue={amount}
        onChange={(e) => setAmount(parseFloat(e.target.value))}
        InputProps={{
          endAdornment: <InputAdornment position="end">
            <Tooltip title="Will be rounded down to two places behind the comma. Eg: 12.347 will become 12.35">
              <InfoIcon />
            </Tooltip>
          </InputAdornment>
        }}
      />
    </FormControl>
  );
}