import { Button } from "@mui/material";

interface SubmitButtonProps {
  label: string
}

export function SubmitButton(props: SubmitButtonProps) {
  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      style={{ marginTop: "1em" }}
      type="submit"
    >
      {props.label}
    </Button>
  );
}