import { MenuItem, Select } from "@mui/material";
import { Character } from "../../../domain/types";

import "./header.css";

interface HeaderProps {
  character: Character | undefined,
  characters: Character[];
  setCharacter: (character: Character) => void,
}

interface CharacterDropdownProps {
  character: Character | undefined,
  characters: Character[];
  handleSwitch: (characterId: number | string) => void,
}

function CharacterDropdown(props: CharacterDropdownProps) {
  const selectStyle = {
    width: 200,
    height: 30,
  }

  if (props.characters.length === 0) {
    return (<Select sx={selectStyle}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={-1}
    >
      <MenuItem key="none" value={-1}>Create character</MenuItem>
    </Select>)
  }

  return (
    <Select
      sx={selectStyle}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={props.character?.id ?? props.characters[0].id ?? -1}
      onChange={(event) => props.handleSwitch(event.target.value)}
    >
      {props.characters.map(c => (
        <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
      ))}
      <MenuItem key="none" value={-1}>Create character</MenuItem>
    </Select>)
}

export function Header(props: HeaderProps) {
  const handleSwitch = (characterId: number | string) => {
    const character = props.characters.filter(c => c.id === characterId)[0];
    if (character) {
      props.setCharacter(character);
    } else {
      alert('No character selected, please create a new one');
    }
  }

  return (
    <div className="header">
      <h2>Banking</h2>
      <CharacterDropdown
        character={props.character}
        characters={props.characters}
        handleSwitch={handleSwitch}
      />
    </div>)
}