import { FormControl, TextField } from "@mui/material";

interface DescriptionProps {
  description: string,
  setDescription: (description: string) => void
}

export function Description(props: DescriptionProps) {
  const { description, setDescription } = props;

  return (
    <FormControl fullWidth>
      <TextField
        required
        label="Description"
        defaultValue={description}
        onChange={(e) => setDescription(e.target.value)}
      >
      </TextField>
    </FormControl >
  );
}