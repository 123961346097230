import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import React from "react";
import { Account, Character } from "../../../../../domain";
import { addAccount } from "../../../../../process";

interface AddAccountDialogProps {
  open: boolean,
  setOpen: (open: boolean) => void
  character: Character,
  accounts: Account[]
}

function createNewAccount(name: string, setOpen: (open: boolean) => void, setError: (error: string) => void, accounts: Account[], character: Character) {
  if (name === "") {
    setError("Account name cannot be empty");
    return;
  }

  if (accounts.find(account => account.name === name)) {
    setError("Account name already exists");
    return;
  }

  addAccount(character.id, name)
    .then(() => setOpen(false));
}

export function AddAccountDialog(props: AddAccountDialogProps) {
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState("");

  const { character, open, setOpen, accounts } = props;

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Specify a name for the new account</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="New account name"
          type="text"
          fullWidth
          variant="standard"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        {error !== "" ? <p style={{ color: "red", marginBottom: 0 }}>{error}</p> : <></>}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setValue("");
          setError("");
          setOpen(false);
        }}>Cancel</Button>
        <Button onClick={() => {
          const name = value.trim();
          createNewAccount(name, setOpen, setError, accounts, character);
          setValue("");
        }}>Add</Button>
      </DialogActions>
    </Dialog>
  );
}
