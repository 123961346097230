interface ErrorMessageProps {
  message: string
}

export function ErrorMessage(props: ErrorMessageProps) {
  const { message } = props;

  return message !== ""
    ? <p style={{ color: "red", marginBottom: "10px" }}>{message}</p>
    : (<><br /><br /></>);
}