import { api, Endpoint } from "../data/api";
import { ActionWithParam, Character } from "../domain/types";

export function getCharacters(userId: number, setCharacters: ActionWithParam<Character[]>, setActiveCharacter: ActionWithParam<Character>) {
  return api.post(Endpoint.getCharacters, { userId })
    .then(items => {
      if (items.length === 0)
        return;

      setActiveCharacter(items[0]);
      setCharacters(items);
    });
}