import { Endpoint, api } from "../data/api";
import { Account, Money } from "../domain";

export function sendMoney(amount: number, from: Account, to: string, description: string): Promise<any[]> {
  if (from.combineFunds() < amount) {
    return Promise.reject("Not enough funds");
  }

  const wholeAndCents = Money.fromNumber(amount).wholeAndCents;
  const data = {
    accountId: from.id,
    cp: wholeAndCents.cents,
    sp: 0,
    gp: wholeAndCents.whole,
    ep: 0,
    pp: 0,
    recipient: to,
    description: description
  }

  return api.post(Endpoint.sendMoney, data);
}