import { Box, Button, TextField, } from "@mui/material";
import React from "react";
import { login } from "../../../process";
import "./index.css";

interface LoginProps {
  setUser: (user: any) => void
}

export default function Login(props: LoginProps) {
  const [_username, setUsername] = React.useState("");
  const [_password, setPassword] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const loginUser = () => login(_username, _password)
    .then(users => props.setUser(users[0]))
    .catch(() => setErrorMessage("Username or password was wrong, please try again"));

  return (
    <Box className="login">
      <Box className="login-box">
        <h2 style={{ marginTop: 0 }}>DnD Banking</h2>
        <Box className="login-box-input">
          <TextField onChange={(event) => setUsername(event.target.value)} className="input" label="Username" variant="outlined" />
          <TextField onChange={(event) => setPassword(event.target.value)} className="input" label="Password" variant="outlined" type="Password" />
        </Box>
        <Button variant="contained" onClick={loginUser}>Log in</Button>
        {errorMessage !== "" ? <p style={{ color: "red", marginBottom: 0 }}>{errorMessage}</p> : <></>}
      </Box>
    </Box>
  );
}